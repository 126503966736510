<script>
import request from "@/utils/request";
import enums from "@/utils/enums";
import {DatabaseSetting,RightUser,Editor} from "@icon-park/vue"
import STag from "@/components/STag/index.vue";

export default {
  name:'PayView',
  components: {STag, DatabaseSetting,RightUser,Editor},
  data(){
    return {
      enums,
      loading: false,
      realField:[],
      customerName: '客户',
      single_ids: [
        {
          name: '电视',
          stand: '规格 LRB45',
          code: '编号 0081',
          url: require('@/assets/image/dianshiji.png')
        },
        {
          name: '洗衣机',
          stand: '规格 LRB45',
          code: '编号 0081',
          url: require('@/assets/image/xiyiji.png')
        }
      ],
      goods_list: [
        {
          name: '液晶显示器',
          price: '￥1000',
          num:'数量x1',
          stand: '规格 LRB45',
          code: '编号 0081'
        },
        {
          name: '电视支撑杆',
          price: '￥1000',
          num:'数量x1',
          stand: '规格 LRB45',
          code: '编号 0081'
        }
      ],
      service_list: [
        {
          name: '维修服务',
          price: '￥1000'
        },
        {
          name: '安装服务',
          price: '￥1000'
        }
      ],
    }
  },
  created() {
    this.customerName = this.$route.query.c_n || "客户"
    const { id } = this.$route.params || {}
    this.id = id
    this.initData(this.id)
  },
  methods:{
    initData(id){
      this.loading = true
      request({
        url: '/new_work_order_type/pay_preview',
        data: {id}
      }).then(res => {
        this.realField = res.data.data
            ? res.data.data.sort((a, b) => (a.order_number - b.order_number))
            : []
      }).catch(() =>
        this.$router.push({name: '404'})
      ).finally(()=>{
        this.loading=false
      })
    }
  }
}
</script>

<template>
  <a-spin :spinning="loading">
    <div class="body">
      <div class="warn" style="background: #FFF6E5;">
        <a-icon class="warn-icon" style="color: #FA7216;" type="info-circle" theme="filled" />
        下方为非真实的样例数据，仅供预览。
      </div>
      <div class="title">
        {{ customerName }}付款
      </div>
      <template v-if="realField.length > 0">
        <div class="container">
          <div v-for="(item,index) in realField" :key="index">
            <template v-if="item.name === 'code'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">20240101</div>
              </div>
            </template>
            <template v-else-if="item.name === 'handlers'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">白经理</div>
              </div>
            </template>
            <template v-else-if="item.name === 'helpers'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">马经理</div>
              </div>
            </template>
            <template v-else-if="item.name === 'customer_id'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">山东芯智能</div>
              </div>
            </template>
            <template v-else-if="item.name === 'linkman_name'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">张经理</div>
              </div>
            </template>
            <template v-else-if="item.name === 'linkman_mobile'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">133xxxx4920</div>
              </div>
            </template>
            <template v-else-if="item.name === 'address'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">北京市朝阳区</div>
              </div>
            </template>
            <template v-else-if="item.name === 'single_ids'">
              <div class="single">
                <div v-for="(single,singleIndex) in single_ids" :key="singleIndex + single.stand">
                  <div class="single-content">
                    <div style="display: flex;flex-direction: column">
                      <div style="display: flex">
                        <div style="color:#262626;font-weight: bold;font-size: 20px">{{ single.name }}</div>
                        <s-tag :v="'5'" text="保内" style="height: 20px;margin: 3px 0 0 5px;"></s-tag>
                      </div>
                      <div style="color:#9a9a9a;font-size: 14px">{{ single.stand }}</div>
                      <div style="color:#9a9a9a;font-size: 14px">{{ single.code }}</div>
                    </div>
                      <img class="iphoto" :src="single.url" alt="">
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="item.name === 'fault_list'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">硬件/屏幕</div>
              </div>
            </template>
            <template v-else-if="item.name === 'content'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">屏幕损坏</div>
              </div>
            </template>
            <template v-else-if="item.name === 'priority_configure_id'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">高</div>
              </div>
            </template>
            <template v-else-if="item.name === 'detection_user_id'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">商经理</div>
              </div>
            </template>
            <template v-else-if="item.name === 'detection_content'">
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">更换屏幕总成</div>
              </div>
            </template>
            <template v-else-if="item.name === 'goods_list'">
              <div class="goods">
                <div style="display: flex;color:#00A578;margin-bottom: -5px">
                  <DatabaseSetting fill="#00A578" theme="filled" class="icon"/>
                  <div style="font-weight: bold;font-size: 20px">{{ item.label }}</div>
                </div>
                <div v-for="(goods,goodsIndex) in goods_list" :key="goods.name+goodsIndex">
                  <div style="display: flex;justify-content: space-between;align-items: center;color: #262626;font-size: 16px">
                    <div>{{ goods.name }}</div>
                    <div>{{ goods.price }}</div>
                  </div>
                  <div style="display: flex;justify-content:space-between;color:#9a9a9a;font-size: 14px">
                    <div>{{ goods.stand }}</div>
                    <div>{{ goods.num }}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div style="margin-right: 5px;font-size: 16px">备件小计:</div>
                  <div style="font-weight: 600;font-size: 20px">￥2000</div>
                </div>
              </div>
            </template>
            <template v-else-if="item.name === 'service_list'">
              <div class="goods">
                <div style="display: flex;color:#00A578;">
                  <RightUser fill="#00A578" theme="filled" class="icon" style="margin-top: -3px"/>
                  <div style="font-weight: bold;font-size: 20px">{{ item.label }}</div>
                </div>
                <div v-for="(service) in service_list" :key="service.name" class="center">
                  <div style="display: flex;justify-content: space-between;align-items: center">
                    <div>{{ service.name }}</div>
                    <div>{{ service.price }}</div>
                  </div>
                </div>
                  <div class="bottom">
                    <div style="margin-right: 5px;font-size: 16px">服务小计:</div>
                    <div style="font-weight: 600;font-size: 20px">￥2000</div>
                  </div>
              </div>
            </template>
            <template v-else-if="item.name === 'customer_sign'">
              <div class="goods">
                <div style="display: flex;color:#00A578;">
                  <Editor fill="#00A578" theme="filled" class="icon"/>
                  <div style="font-weight: bold;font-size: 20px">{{ item.label }}</div>
                </div>
                <div class="sign">
                  <div class="board-sign">
                    {{customerName}}未签字
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="common">
                <div class="label">{{ item.label }}</div>
                <div class="content">无</div>
              </div>
            </template>
          </div>
        </div>
      </template>
      <template v-else>
        <a-empty class="empty" description="暂无数据"></a-empty>
      </template>
      <div class="bottom-button">
        <div class="total">
          <div style="font-size: 16px">合计</div>
          <div style="font-size: 14px;color: red">￥</div>
          <div style="font-size: 20px;color: red;margin-bottom: -3px">1000.00</div>
        </div>
        <div class="pay-btn">
          <a-button type="primary" style="width: 120px">支付</a-button>
        </div>
      </div>
    </div>
  </a-spin>
</template>

<style scoped lang="less">
.body{
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-x: hidden;
  background: #f6f6f6;
  .warn {
    padding: 5px;
    color: #1c2128;
    display: flex;
    align-items: center;
    justify-content: center;
    .warn-icon {
      font-size: 20px;
      margin: 0 5px;
    }
  }
}
.bottom-button {
  position: fixed;
  padding: 1rem 1rem 1.2rem 1rem;
  width: 100%;
  bottom: 0;
  background: #fff;
  z-index: 999;
  display: flex;
  align-items: center;
  .total {
    display: flex;
    flex: 5;
    justify-content: center;
    align-items: flex-end;
  }
  .pay-btn {
    flex: 5;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
.title {
  padding: 5px 0;
  height: 45px;
  font-size: 16px;
  background: #00A578;
  color: #fff;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}
.empty {
  color: #c1c1c1;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container {
  background-color: #fff;
  margin: 0.8rem 0.8rem 5rem 0.8rem;
  border-radius: 0.5rem;
  overflow-y: auto;
  padding: 1rem;
  font-size: 1rem;
}
::-webkit-scrollbar {
  display: none;
}
.common {
  display: flex;
  justify-content: space-between;
  .label {
    color: #9A9A9A;
    margin: 3px 0;
  }
  .content {
    color: #222a25;
    margin: 3px 0;
  }
}
.single {
  border-top: 1px solid #ededed;
  border-bottom: 1px solid #ededed;
  margin: 10px 0;
  padding: 10px 0;
  .single-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .iphoto {
      width: 60px;
      height: 60px;
      border-radius: 5px
    }
  }
}
.goods {
  .icon{
    margin-top: -1px;
    margin-right: 5px;
    font-size: 26px;
  }
  .bottom {
    margin-top: 10px;
    padding: 10px 0 0 0;
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    color: #262626;
  }
  .sign {
    margin: 10px;
    .board-sign {
      //width: 300px;
      height: 150px;
      border: 1px solid #f0f0f0;
      border-radius: 5px;
      color: #cccccc;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>